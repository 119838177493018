import React from 'react'

const ExploreEcosystem = () => {
  return (
    <div className="ee_wrapper">

    <section className="work-part pt-100">
        <div className="container"> 
            <div className="row">
                <div className="col-md-6 flex-align " >
                    <div className="work-box">
                        <div className="section-heading text-start"> 
                            <h2 className="heading-title">Protolink <br/><span>Ecosystem</span></h2>
                            <p className="heading-des"> The ProtoLink Ecosystem is a dynamic and interconnected environment powered by cutting-edge blockchain technology. It serves as the foundation for secure and efficient cross-chain transfers, revolutionizing the way users interact with cryptocurrencies.</p>
                        </div> 
                    </div>
                </div>
                
                <div className="col-md-6 text-center flex-align justify-center " >
                    <div className="work-box">
                        <div className="work-box-bg"></div>
                        <img src="images/work-process.png" className="rotation-img" alt="Work Process"/>
                    </div>
                </div>



            </div>
        </div>
    </section>

    <section className="sm_sec sm_sec_in">
        <div className="container"> 
            <div className=" ">
                <h3 className="heading-title-sm">Best Features</h3> 
            </div>
            <div className="row g-3"> 
                
                <div className="col-lg-6">
                  <div className="clint">
                    <div className="client_header" >
                        <img src="images/heliex_img.png" width="70" alt="" className="img-fluid" />
                        <div>
                            <h2>
                            ProtoLink Wallet
                            </h2>
                            {/* <span className="badge badge-primary" >Orderbook Exchange</span> */}
                        </div>
                    </div>
                    <div className="clint-box">
                       <p>
                           <strong>Description</strong>
                           
                           The ProtoLink Wallet serves as a secure and efficient hub for managing PTL tokens and other supported assets. Users can initiate cross-chain transfers, stake tokens for governance, and interact with various ProtoLink features—all within a user-friendly wallet interface.


                       </p>
{/* 
                       <p>
                           <strong>Utilize </strong>
                           dApp, Crypto wallet 
                       </p> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="clint">
                    <div className="client_header" >
                        <img src="images/heliex_img.png" width="70" alt="" className="img-fluid" />
                        <div>
                            <h2>
                            Cross-Chain Asset Swapper
                            </h2>
                            {/* <span className="badge badge-primary" >Orderbook Exchange</span> */}
                        </div>
                    </div>
                    <div className="clint-box">
                       <p>
                           <strong>Description</strong>
                           
                           Leverage ProtoLink's cross-chain compatibility to seamlessly swap assets across different blockchain networks. With the ProtoLink Wallet, users can initiate asset swaps directly from a secure and user-friendly interface.
                       </p> 
                       {/* <p>
                           <strong>Utilize </strong>
                           dApp, Crypto wallet 
                       </p> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="clint">
                    <div className="client_header" >
                        <img src="images/heliex_img.png" width="70" alt="" className="img-fluid" />
                        <div>
                            <h2>
                            Decentralized Finance (DeFi) Yield Optimizer
                            </h2>
                            {/* <span className="badge badge-primary" >Orderbook Exchange</span> */}
                        </div>
                    </div>
                    <div className="clint-box">
                       <p>
                           <strong>Description</strong>
                           
                           Utilize ProtoLink's low transaction fees and cross-chain capabilities in a dedicated DeFi DApp. The ProtoLink Wallet enhances user experience by providing a one-stop solution for optimizing liquidity provision and yield farming strategies across various protocols.
                       </p> 
                       {/* <p>
                           <strong>Utilize </strong>
                           dApp, Crypto wallet 
                       </p> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="clint">
                    <div className="client_header" >
                        <img src="images/heliex_img.png" width="70" alt="" className="img-fluid" />
                        <div>
                            <h2>
                            Governance Participation Hub 
                            </h2>
                            {/* <span className="badge badge-primary" >Orderbook Exchange</span> */}
                        </div>
                    </div>
                    <div className="clint-box">
                       <p>
                           <strong>Description</strong>
                           
                           Access a centralized hub within the ProtoLink Wallet for streamlined participation in the decentralized governance of supported blockchain projects. PTL token holders can conveniently vote, propose changes, and actively contribute to protocol development directly from their wallet.
                       </p> 
                       {/* <p>
                           <strong>Utilize </strong>
                           dApp, Crypto wallet 
                       </p> */}
                    </div>
                  </div>
                </div> 
            </div> 
        </div>
      </section> 
      
      
      <section className="cry_ser  ptb-100">
        <div className="container">
            <div className=" ">
                <h3 className="heading-title-sm">Explore Protolink Ecosystem</h3> 
            </div>
            <div className="row">
                <div className="col-md-4 col-xl-3">
                    <div className="d-flex align-items-start mobile-scroll" >

                        <ul className="nav nav-tab flex-column nav-pills me-3  ">
                            <li className="services-title d-flex align-items-center" >
                                <a className="tab-link active" href="#/" data-tab="tab-1"> 
                                    <i className="ri-arrow-right-s-line me-1"></i> View all</a>
                            </li>
                            <li className="services-title d-flex align-items-center" >
                                <a className="tab-link" href="#/" data-tab="tab-2"> 
                                    <i className="ri-arrow-right-s-line me-1"></i> Featured dApps</a>
                            </li>
                            <li className="services-title d-flex align-items-center" >
                                <a className="tab-link" href="#/" data-tab="tab-3"> 
                                    <i className="ri-arrow-right-s-line me-1"></i> Apps & Tooling</a>
                            </li>
                            <li className="services-title d-flex align-items-center" >
                                <a className="tab-link" href="#/" data-tab="tab-4"> 
                                    <i className="ri-arrow-right-s-line me-1"></i> Blockchains</a>
                            </li>
                            <li className="services-title d-flex align-items-center" >
                                <a className="tab-link" href="#/" data-tab="tab-5"> 
                                    <i className="ri-arrow-right-s-line me-1"></i> Data</a>
                            </li> 
                            <li className="services-title d-flex align-items-center" >
                                <a className="tab-link" href="#/" data-tab="tab-6"> 
                                    <i className="ri-arrow-right-s-line me-1"></i> Institutions</a>
                            </li> 
                            <li className="services-title d-flex align-items-center" >
                                <a className="tab-link" href="#/" data-tab="tab-7"> 
                                    <i className="ri-arrow-right-s-line me-1"></i> Interoperability</a>
                            </li> 
                            <li className="services-title d-flex align-items-center" >
                                <a className="tab-link" href="#/" data-tab="tab-8"> 
                                    <i className="ri-arrow-right-s-line me-1"></i> Stable Coins</a>
                            </li> 
                            <li className="services-title d-flex align-items-center" >
                                <a className="tab-link" href="#/" data-tab="tab-9"> 
                                    <i className="ri-arrow-right-s-line me-1"></i> Venture Capital</a>
                            </li> 
                            <li className="services-title d-flex align-items-center" >
                                <a className="tab-link" href="#/" data-tab="tab-10"> 
                                    <i className="ri-arrow-right-s-line me-1"></i> Wallets</a>
                            </li> 
                        </ul>
                    </div>
                </div>

                <div className="col-md-8 col-xl-9 tab-content"  > 

                        <div className="tab-panel active" id="tab-1">
                            <div className="row g-2 justify-content-center roc_scroll_m">
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service1">
                                        <div className="service-icon"> <img src="images/dpip_1.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2> Exotic Markets </h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2  ">
                                    <a href="#/" className="single-service service2">
                                        <div className="service-content">
                                            <h2>Helix</h2> 
                                        </div>
                                        <div className="service-icon"> <img src="images/dpip_2.png" width="60" className="img-fluid" alt=""/> </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2   ">
                                    <a href="#/" className="single-service service3">
                                        <div className="service-icon"> <img src="images/dpip_3.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2>Hydro Protocol</h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service4">
                                        <div className="service-content">
                                            <h2>Mito</h2> 
                                        </div>
                                        <div className="service-icon"> <img src="images/dpip_4.png" width="60" className="img-fluid" alt=""/> </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service5">
                                        <div className="service-icon"> <img src="images/dpip_4.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2> Neptune </h2> 
                                        </div>
                                    </a>
                                </div> 
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service1">
                                        <div className="service-icon"> <img src="images/dpip_1.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2> Exotic Markets </h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2  ">
                                    <a href="#/" className="single-service service2">
                                        <div className="service-content">
                                            <h2>Helix</h2> 
                                        </div>
                                        <div className="service-icon"> <img src="images/dpip_2.png" width="60" className="img-fluid" alt=""/> </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2   ">
                                    <a href="#/" className="single-service service3">
                                        <div className="service-icon"> <img src="images/dpip_3.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2>Hydro Protocol</h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service4">
                                        <div className="service-content">
                                            <h2>Mito</h2> 
                                        </div>
                                        <div className="service-icon"> <img src="images/dpip_4.png" width="60" className="img-fluid" alt=""/> </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service5">
                                        <div className="service-icon"> <img src="images/dpip_4.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2> Neptune </h2> 
                                        </div>
                                    </a>
                                </div> 
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service1">
                                        <div className="service-icon"> <img src="images/dpip_1.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2> Exotic Markets </h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2  ">
                                    <a href="#/" className="single-service service2">
                                        <div className="service-content">
                                            <h2>Helix</h2> 
                                        </div>
                                        <div className="service-icon"> <img src="images/dpip_2.png" width="60" className="img-fluid" alt=""/> </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2   ">
                                    <a href="#/" className="single-service service3">
                                        <div className="service-icon"> <img src="images/dpip_3.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2>Hydro Protocol</h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service4">
                                        <div className="service-content">
                                            <h2>Mito</h2> 
                                        </div>
                                        <div className="service-icon"> <img src="images/dpip_4.png" width="60" className="img-fluid" alt=""/> </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service5">
                                        <div className="service-icon"> <img src="images/dpip_4.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2> Neptune </h2> 
                                        </div>
                                    </a>
                                </div> 
                            </div> 
                        </div> 

                        <div className="tab-panel" id="tab-2">
                            <div className="row g-2 justify-content-center roc_scroll_m">
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service1">
                                        <div className="service-icon"> <img src="images/dpip_1.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2> Exotic Markets </h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2  ">
                                    <a href="#/" className="single-service service2">
                                        <div className="service-content">
                                            <h2>Helix</h2> 
                                        </div>
                                        <div className="service-icon"> <img src="images/dpip_2.png" width="60" className="img-fluid" alt=""/> </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2   ">
                                    <a href="#/" className="single-service service3">
                                        <div className="service-icon"> <img src="images/dpip_3.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2>Hydro Protocol</h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service4">
                                        <div className="service-content">
                                            <h2>Mito</h2> 
                                        </div>
                                        <div className="service-icon"> <img src="images/dpip_4.png" width="60" className="img-fluid" alt=""/> </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service5">
                                        <div className="service-icon"> <img src="images/dpip_4.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2> Neptune </h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service view_all">
                                        <div className="service-content">
                                            <i className="ri-arrow-right-up-line ri-xl mb-2"></i>
                                            <h2> View All </h2> 
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="tab-panel" id="tab-3">
                            <div className="row g-2 justify-content-center roc_scroll_m">
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service1">
                                        <div className="service-icon"> <img src="images/dpip_1.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2> Exotic Markets </h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2  ">
                                    <a href="#/" className="single-service service2">
                                        <div className="service-content">
                                            <h2>Helix</h2> 
                                        </div>
                                        <div className="service-icon"> <img src="images/dpip_2.png" width="60" className="img-fluid" alt=""/> </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2   ">
                                    <a href="#/" className="single-service service3">
                                        <div className="service-icon"> <img src="images/dpip_3.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2>Hydro Protocol</h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service4">
                                        <div className="service-content">
                                            <h2>Mito</h2> 
                                        </div>
                                        <div className="service-icon"> <img src="images/dpip_4.png" width="60" className="img-fluid" alt=""/> </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service5">
                                        <div className="service-icon"> <img src="images/dpip_4.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2> Neptune </h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service view_all">
                                        <div className="service-content">
                                            <i className="ri-arrow-right-up-line ri-xl mb-2"></i>
                                            <h2> View All </h2> 
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="tab-panel" id="tab-4">
                            <div className="row g-2 justify-content-center roc_scroll_m">
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service1">
                                        <div className="service-icon"> <img src="images/dpip_1.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2> Exotic Markets </h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2  ">
                                    <a href="#/" className="single-service service2">
                                        <div className="service-content">
                                            <h2>Helix</h2> 
                                        </div>
                                        <div className="service-icon"> <img src="images/dpip_2.png" width="60" className="img-fluid" alt=""/> </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2   ">
                                    <a href="#/" className="single-service service3">
                                        <div className="service-icon"> <img src="images/dpip_3.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2>Hydro Protocol</h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service4">
                                        <div className="service-content">
                                            <h2>Mito</h2> 
                                        </div>
                                        <div className="service-icon"> <img src="images/dpip_4.png" width="60" className="img-fluid" alt=""/> </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service5">
                                        <div className="service-icon"> <img src="images/dpip_4.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2> Neptune </h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service view_all">
                                        <div className="service-content">
                                            <i className="ri-arrow-right-up-line ri-xl mb-2"></i>
                                            <h2> View All </h2> 
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="tab-panel" id="tab-5">
                            <div className="row g-2 justify-content-center roc_scroll_m">
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service1">
                                        <div className="service-icon"> <img src="images/dpip_1.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2> Exotic Markets </h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2  ">
                                    <a href="#/" className="single-service service2">
                                        <div className="service-content">
                                            <h2>Helix</h2> 
                                        </div>
                                        <div className="service-icon"> <img src="images/dpip_2.png" width="60" className="img-fluid" alt=""/> </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2   ">
                                    <a href="#/" className="single-service service3">
                                        <div className="service-icon"> <img src="images/dpip_3.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2>Hydro Protocol</h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service4">
                                        <div className="service-content">
                                            <h2>Mito</h2> 
                                        </div>
                                        <div className="service-icon"> <img src="images/dpip_4.png" width="60" className="img-fluid" alt=""/> </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service5">
                                        <div className="service-icon"> <img src="images/dpip_4.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2> Neptune </h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service view_all">
                                        <div className="service-content">
                                            <i className="ri-arrow-right-up-line ri-xl mb-2"></i>
                                            <h2> View All </h2> 
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div> 
                        <div className="tab-panel" id="tab-6">
                            <div className="row g-2 justify-content-center roc_scroll_m">
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service1">
                                        <div className="service-icon"> <img src="images/dpip_1.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2> Exotic Markets </h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2  ">
                                    <a href="#/" className="single-service service2">
                                        <div className="service-content">
                                            <h2>Helix</h2> 
                                        </div>
                                        <div className="service-icon"> <img src="images/dpip_2.png" width="60" className="img-fluid" alt=""/> </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2   ">
                                    <a href="#/" className="single-service service3">
                                        <div className="service-icon"> <img src="images/dpip_3.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2>Hydro Protocol</h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service4">
                                        <div className="service-content">
                                            <h2>Mito</h2> 
                                        </div>
                                        <div className="service-icon"> <img src="images/dpip_4.png" width="60" className="img-fluid" alt=""/> </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service5">
                                        <div className="service-icon"> <img src="images/dpip_4.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2> Neptune </h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service view_all">
                                        <div className="service-content">
                                            <i className="ri-arrow-right-up-line ri-xl mb-2"></i>
                                            <h2> View All </h2> 
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div> 
                        <div className="tab-panel" id="tab-7">
                            <div className="row g-2 justify-content-center roc_scroll_m">
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service1">
                                        <div className="service-icon"> <img src="images/dpip_1.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2> Exotic Markets </h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2  ">
                                    <a href="#/" className="single-service service2">
                                        <div className="service-content">
                                            <h2>Helix</h2> 
                                        </div>
                                        <div className="service-icon"> <img src="images/dpip_2.png" width="60" className="img-fluid" alt=""/> </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2   ">
                                    <a href="#/" className="single-service service3">
                                        <div className="service-icon"> <img src="images/dpip_3.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2>Hydro Protocol</h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service4">
                                        <div className="service-content">
                                            <h2>Mito</h2> 
                                        </div>
                                        <div className="service-icon"> <img src="images/dpip_4.png" width="60" className="img-fluid" alt=""/> </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service5">
                                        <div className="service-icon"> <img src="images/dpip_4.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2> Neptune </h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service view_all">
                                        <div className="service-content">
                                            <i className="ri-arrow-right-up-line ri-xl mb-2"></i>
                                            <h2> View All </h2> 
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div> 
                        <div className="tab-panel" id="tab-8">
                            <div className="row g-2 justify-content-center roc_scroll_m">
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service1">
                                        <div className="service-icon"> <img src="images/dpip_1.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2> Exotic Markets </h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2  ">
                                    <a href="#/" className="single-service service2">
                                        <div className="service-content">
                                            <h2>Helix</h2> 
                                        </div>
                                        <div className="service-icon"> <img src="images/dpip_2.png" width="60" className="img-fluid" alt=""/> </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2   ">
                                    <a href="#/" className="single-service service3">
                                        <div className="service-icon"> <img src="images/dpip_3.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2>Hydro Protocol</h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service4">
                                        <div className="service-content">
                                            <h2>Mito</h2> 
                                        </div>
                                        <div className="service-icon"> <img src="images/dpip_4.png" width="60" className="img-fluid" alt=""/> </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service5">
                                        <div className="service-icon"> <img src="images/dpip_4.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2> Neptune </h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service view_all">
                                        <div className="service-content">
                                            <i className="ri-arrow-right-up-line ri-xl mb-2"></i>
                                            <h2> View All </h2> 
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div> 
                        <div className="tab-panel" id="tab-9">
                            <div className="row g-2 justify-content-center roc_scroll_m">
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service1">
                                        <div className="service-icon"> <img src="images/dpip_1.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2> Exotic Markets </h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2  ">
                                    <a href="#/" className="single-service service2">
                                        <div className="service-content">
                                            <h2>Helix</h2> 
                                        </div>
                                        <div className="service-icon"> <img src="images/dpip_2.png" width="60" className="img-fluid" alt=""/> </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2   ">
                                    <a href="#/" className="single-service service3">
                                        <div className="service-icon"> <img src="images/dpip_3.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2>Hydro Protocol</h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service4">
                                        <div className="service-content">
                                            <h2>Mito</h2> 
                                        </div>
                                        <div className="service-icon"> <img src="images/dpip_4.png" width="60" className="img-fluid" alt=""/> </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service5">
                                        <div className="service-icon"> <img src="images/dpip_4.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2> Neptune </h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service view_all">
                                        <div className="service-content">
                                            <i className="ri-arrow-right-up-line ri-xl mb-2"></i>
                                            <h2> View All </h2> 
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div> 
                        <div className="tab-panel" id="tab-10">
                            <div className="row g-2 justify-content-center roc_scroll_m">
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service1">
                                        <div className="service-icon"> <img src="images/dpip_1.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2> Exotic Markets </h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2  ">
                                    <a href="#/" className="single-service service2">
                                        <div className="service-content">
                                            <h2>Helix</h2> 
                                        </div>
                                        <div className="service-icon"> <img src="images/dpip_2.png" width="60" className="img-fluid" alt=""/> </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2   ">
                                    <a href="#/" className="single-service service3">
                                        <div className="service-icon"> <img src="images/dpip_3.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2>Hydro Protocol</h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service4">
                                        <div className="service-content">
                                            <h2>Mito</h2> 
                                        </div>
                                        <div className="service-icon"> <img src="images/dpip_4.png" width="60" className="img-fluid" alt=""/> </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service service5">
                                        <div className="service-icon"> <img src="images/dpip_4.png" width="60" className="img-fluid" alt=""/> </div>
                                        <div className="service-content">
                                            <h2> Neptune </h2> 
                                        </div>
                                    </a>
                                </div>
                                <div className="col-6 col-md-4 col-xl-2 ">
                                    <a href="#/" className="single-service view_all">
                                        <div className="service-content">
                                            <i className="ri-arrow-right-up-line ri-xl mb-2"></i>
                                            <h2> View All </h2> 
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div> 
                </div>
                 
            </div>
        </div> 
    </section>



    <div className="section-process ptb-100">
        <div className="container">
            <div className="w-layout-grid grid-process-section">
                <div className="process-content"> 
                    <div className="title-large">PROCESS</div>
                    <h3 className="h3-heading"> How to get started with ProtoLink ? </h3>
                </div>
                <div>
                    <div className="w-layout-grid grid-process-stacked">
                        <div className="card-process">
                            <div className="process-icon">
                                <div>1</div>
                            </div>
                            <div className="heading-large"> Create an Protolink Wallet </div>
                        </div>
                        <div className="card-process">
                            <div className="process-icon">
                                <div>2</div>
                            </div>
                            <div className="heading-large">Get PLT</div>
                        </div>
                        <div className="card-process">
                            <div className="process-icon">
                                <div>3</div>
                            </div>
                            <div className="heading-large">Explore Protolink Ecosystem </div>
                        </div>
                        <div className="card-process">
                            <div className="process-icon">
                                <div>4</div>
                            </div>
                            <div className="heading-large">Join the community </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>
  )
}

export default ExploreEcosystem
