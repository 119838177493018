import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <header className="transition" >
    <div className="container">
        <div className="row flex-align">
            <div className="col-lg-4 col-md-3 col-8">
                <div className="logo">
                    <a href="/"><img src="images/logo.png" className="transition" alt="Cryptcon"/></a>
                </div>
            </div>
            <div className="col-lg-8 col-md-9 col-4 text-right">
                <div className="menu-toggle">
                    <span></span>
                </div>
                <div className="menu">
                    <ul className="d-inline-block">
                        <li className="mega-menu">
                            <span className="opener plus"></span>
                            <a href="#">Developers</a>
                            <ul className="transition grid gap-2 grid-cols-1">
                                <li>
                                    <a href="/developer_overview" className="grid_menu" >
                                        <div className="d-flex align-items-center">
                                            <i className="ri-code-s-slash-fill me-4"></i>
                                            <div>
                                                <span>Developer Overview</span>
                                                <small>  Learn how to start building on ProtoLink</small>
                                            </div>
                                        </div>
                                    </a>
                                </li> 
                            </ul>
                        </li>
                        <li className="mega-menu">
                            <span className="opener plus"></span>
                            <a href="#">Individuals</a>
                            <ul className="transition grid gap-2 grid-cols-1">
                                <li>
                                    <a href="/explore_ecyosystem" className="grid_menu" >
                                        <div className="d-flex align-items-center">
                                            <i className="ri-earth-line me-4"></i>
                                            <div>
                                                <span>Explore Ecosystem </span>
                                                <small>  Explore the global network of dApps, institutions and partners building the future of finance
                                                    Overview</small>
                                            </div>
                                        </div>
                                    </a>
                                </li> 
                                <li>
                                    <a href="/get_proto" className="grid_menu" >
                                        <div className="d-flex align-items-center">
                                            <i className="ri-hand-coin-line me-4"></i>
                                            <div>
                                                <span>Get Proto  </span>
                                                <small>  Access the asset powering the Protolink ecosystem</small>
                                            </div>
                                        </div>
                                    </a>
                                </li> 
                            </ul>
                        </li> 
                        <li className="mega-menu">
                            <span className="opener plus"></span>
                            <a href="#">Institutions</a>
                            <ul className="transition grid gap-2 grid-cols-1">
                                <li>
                                    <a href="/institution_overview" className="grid_menu" >
                                        <div className="d-flex align-items-center">
                                            <i className="ri-bank-line me-4"></i>
                                            <div>
                                                <span>Institutions Overview</span>
                                                <small>  Learn why the leading financial institutions are backing Protolink</small>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </li> 
                        <li className="mega-menu">
                            <span className="opener plus"></span>
                            <a href="#">Ecosystem</a>
                            <ul className="transition grid gap-2 grid-cols-1">
                                <li>
                                    <a href="/explore_ecyosystem" className="grid_menu" >
                                        <div className="d-flex align-items-center">
                                            <i className="ri-global-line me-4"></i>
                                            <div>
                                                <span>Explore Ecosystem</span>
                                                <small>  Explore the global network of dApps, institutions and partners building the future of finance</small>
                                            </div>
                                        </div>
                                    </a>
                                </li> 
                            </ul>
                        </li> 
                        <li className="mega-menu">
                            <span className="opener plus"></span>
                            <a href="#">About</a>
                            <ul className="transition grid gap-2 grid-cols-1">
                                <li>
                                    <a href="/about" className="grid_menu" >
                                        <div className="d-flex align-items-center">
                                            <i className="ri-search-eye-line me-4"></i>
                                            <div>
                                                <span>About Protolink </span>
                                                <small> Learn more about the Protolink mission and roadmap   Overview</small>
                                            </div>
                                        </div>
                                    </a>
                                </li> 
                            </ul>
                        </li> 
                    </ul> 
                </div>
            </div>
        </div>
    </div>
</header>
  )
}

export default Header
