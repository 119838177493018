import React from 'react'

const Footer = () => {
  return (
    <footer className="bg-pattern lightskyblue ptb-100">
    <div className="container">
        <div className="footer">
            <div className="row gx-5">
                <div className="col-lg-3 col-md-5">
                    <div className="footer-logo pb-25">
                    <a href="/"><img src="images/logo.png" alt="Cryptcon"/></a>
                    <div className="subscribe">
                        <div className="form-group">
                            <label>Stay in the loop</label>
                            <div className="su_control" >
                                <input type="email" className="form-control" placeholder="Enter your email Address"/>
                                <button type="submit"  className="submit"><i className="ri-arrow-right-line"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div className="col-lg-9 col-md-7">
                    <div className="row" >
                        <div className="col-lg-3 col-md-6  col-6" >
                            <div className="footer-link">
                                <h4>Developers</h4>
                                <ul>
                                    <li><a href="/developer_overview">Developer Overview </a></li>
                                    <li><a href="/developer_overview">Documentation</a></li> 
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6  col-6" >
                            <div className="footer-link">
                                <h4>Individuals</h4>
                                <ul>
                                    <li><a href="/explore_ecyosystem">Explore Ecosystem </a></li>
                                    <li><a href="/explore_ecyosystem">Stake ProtoLink</a></li> 
                                    <li><a href="/get_proto">Get ProtoLink </a></li> 
                                    <li><a href="/get_proto">ProtoLink Hub </a></li>
                                    {/* <li><a href="/explore_ecyosystem">ProtoLink Explorer </a></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6  col-6" >
                            <div className="footer-link">
                                <h4>Institutions</h4>
                                <ul> 
                                    <li><a href="/institution_overview">Institutions Overview</a></li> 
                                    <li><a href="/institution_overview">Join the Institution Program </a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-6" >
                            <div className="footer-link">
                                <h4>About</h4>
                                <ul>
                                    <li><a href="/about">About ProtoLink </a></li>
                                    <li><a href="#/">Careers</a></li> 
                                    <li><a href="#/">Blog </a></li>
                                    <li><a href="#/">ProtoLink Labs </a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
        <div className="copyright">
            <div className="row alin-items-center ">
                <div className="col-lg-6">
                    <div className="d-flex" >
                        <p>© ProtoLink, Inc.</p>
                        <ul>
                            <li><a href="#/">Terms & Condition</a></li>
                            <li><a href="#/">Privacy Policy</a></li>
                            <li><a href="#/">Contact Us</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-6"> 
                    <div className="footer-icon">
                        <ul>
                            <li><a href="#/"><i className="ri-facebook-circle-line"></i></a></li>
                            <li><a href="#/"><i className="ri-twitter-line"></i></a></li>
                            <li><a href="#/"><i className="ri-linkedin-line"></i></a></li>
                            <li><a href="#/"><i className="ri-instagram-line"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
  )
}

export default Footer
