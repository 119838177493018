import React from 'react'

const About = () => {
  return (
    <div class="ee_wrapper about_wrapper">

    <section class="work-part pt-100">
        <div class="container"> 
            <div class="row">
                <div class="col-md-6 flex-align " >
                    <div class="work-box">
                        <div class="section-heading text-start"> 
                            <label> Welcome to PortoLink </label>
                            <h2 class="heading-title">where blockchain meets boundless opportunities. </h2>
                            <p class="heading-des ms-0"> Welcome to the PortoLink universe, where the potential of blockchain technology knows no bounds. PortoLink is a revolutionary cross-chain protocol, and here's what you need to know about us:
                             </p>
                        </div> 
                    </div>
                </div>
                
                <div class="col-md-6 text-center flex-align justify-center " >
                    <div class="work-box">
                        <div class="work-box-bg"></div>
                        <img src="images/about_globe.svg" class="img-fluid" alt="Work Process"/>
                    </div>
                </div>



            </div>
        </div>
    </section>

    <section class=" tip_sec pt-100">
        <div class="container"> 
             <div class="wip_card" >
                <div class="row gx-5 align-items-center" >

                
                <div class="col-md-6" >
                    <div class="wip_content" >
                        <h3>
                            About Us:
                        </h3>
                        <p class="img-fluid" >
                            <small>
                                At PortoLink, we're pioneering the next generation of blockchain technology. 
                                Our vision is to connect the dots in the blockchain space, enabling seamless, secure, 
                                and cost-effective asset transfers between different blockchain networks. We're at the 
                                forefront of a global movement, shaping the future of decentralized finance and blockchain 
                                interoperability.

                            </small>
                        </p>
                    </div>
                </div>

                <div class="col-md-5 text-center text-md-end" >
                    <img src="images/about_main.png" class="img-fluid" alt="" />
                </div>


                </div>


             </div>
        </div>
    </section>

    <section class="feature-part feature-part-about pt-100 pb-10">
        <div class="container">
            <div class="cc_card" >
                <div class="row ">
                    <div class="col-md-12">
                        <div class="section-heading text-center pb-50">
                            <label class="sub-heading"> Features </label>
                            <h2 class="heading-title"> Our Core Features </h2>
                        </div>
                    </div>
                </div>
                <div class="row g-3 justify-content-center">
                    <div class="col-md-6 col-lg-4  ">
                        <div class="feature-box febx_about">
                            <div>
                                <div class="flex h-20 justify-center">
                                    <img src="images/abf1.png"  width="110" alt="" class="img-fluid"/>
                                </div>
                                <div class="mt-4 text-2xl text-black font-bold">Cross-Chain Transfers</div>
                                <div class="mt-4 text-base leading-6 text-black font-medium">Move your assets across diverse blockchain networks effortlessly.</div>
                            </div>
                        </div>
                    </div> 

                     
                    <div class="col-md-6 col-lg-4  ">
                        <div class="feature-box febx_about">
                            <div>
                                <div class="flex h-20 justify-center">
                                    <img src="images/abf2.png"  width="110" alt="" class="img-fluid"/>
                                </div>
                                <div class="mt-4 text-2xl text-black font-bold">Customized DeFi Solutions </div>
                                <div class="mt-4 text-base leading-6 text-black font-medium"> Tailor your DeFi strategies to meet your unique financial goals.
    </div>
                            </div>
                        </div>
                    </div> 
                    <div class="col-md-6 col-lg-4  ">
                        <div class="feature-box febx_about">
                            <div>
                                <div class="flex h-20 justify-center">
                                    <img src="images/abf3.png"  width="110" alt="" class="img-fluid"/>
                                </div>
                                <div class="mt-4 text-2xl text-black font-bold">Smart Contracts  </div>
                                <div class="mt-4 text-base leading-6 text-black font-medium">Experience a new era of automation and efficiency in your transactions. </div>
                            </div>
                        </div>
                    </div> 
                    <div class="col-md-6 col-lg-4  ">
                        <div class="feature-box febx_about">
                            <div>
                                <div class="flex h-20 justify-center">
                                    <img src="images/abf4.png"  width="110" alt="" class="img-fluid" />
                                </div>
                                <div class="mt-4 text-2xl text-black font-bold">Network Building Rewards </div>
                                <div class="mt-4 text-base leading-6 text-black font-medium">Be part of our community-driven project and get rewarded for your contributions.


                                </div>
                            </div>
                        </div>
                    </div> 
                    <div class="col-md-6 col-lg-4  ">
                        <div class="feature-box febx_about">
                            <div>
                                <div class="flex h-20 justify-center">
                                    <img src="images/abf5.png" width="110" alt="" class="img-fluid"/>
                                </div>
                                <div class="mt-4 text-2xl text-black font-bold">Decentralized Governance:  </div>
                                <div class="mt-4 text-base leading-6 text-black font-medium">Participate in shaping the future of PortoLink through decentralized decision-making.
</div>
                            </div>
                        </div>
                    </div> 


                </div>
            </div>
        </div>
    </section>

    <section class="sm_sec sm_sec_in ">
        <div class="container"> 
            <div class="headt_wimg d-flex ">
                <h3 class="heading-title-sm">Why<br/> PortoLink  </h3> 
                <img src="images/getproto-arrow.svg" width="140" alt="" class="ms-md-4 img-fluid" />
            </div>
            <div class="row g-3"> 
                
                <div class="col-lg-4">
                  <div class="clint h-100">
                    <div class="client_header  mt-3" >
                        <img src="images/wp_1.png" width="70" alt="" class="img-fluid" />
                        <div>
                            <h2>
                                Security
                            </h2> 
                        </div>
                    </div>
                    <div class="clint-box">
                       <p class="mb-0" >
                        Our robust security features ensure the safety of your digital assets.

                       </p> 
                    </div>
                  </div>
                </div> 
                <div class="col-lg-4">
                    <div class="clint h-100">
                      <div class="client_header  mt-3" >
                          <img src="images/wp_2.png" width="70" alt="" class="img-fluid" />
                          <div>
                              <h2>
                                Efficiency
                              </h2> 
                          </div>
                      </div>
                      <div class="clint-box">
                         <p class="mb-0">
                            We prioritize efficient and cost-effective asset management.

                         </p> 
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="clint h-100">
                      <div class="client_header mt-3" >
                          <img src="images/wp_3.png" width="70" alt="" class="img-fluid" />
                          <div>
                              <h2>
                                Accessibility
                              </h2> 
                          </div>
                      </div>
                      <div class="clint-box">
                         <p  class="mb-0">
                            PortoLink is designed for both crypto enthusiasts and newcomers, making it user-friendly and accessible to all.

                         </p> 
                      </div>
                    </div>
                  </div>
            </div> 
        </div>
    </section> 

      <section class="comm_sec ptb-100 ">
        <div class="container" >
            <div class="comm_card" >
                <div class="" >
                    <h3>
                        Join Our Community:
                    </h3>
                    <p>
                        Become a part of the PortoLink community and unlock the limitless possibilities of blockchain technology. Whether you're a blockchain enthusiast, a DeFi pioneer, or a casual user, PortoLink welcomes you to explore, learn, and thrive in the world of decentralized finance.
                    </p>
{/* 
                   <div class="d-flex  joc_social_row ">
                        <a class="joc_social" title=" Facebook" target="_blank" href="#/"><i class="ri-facebook-line  ri-xl"></i></a>
                        <a class="joc_social" title=" Twitter" target="_blank" href="#/"><i class="ri-twitter-line  ri-xl"></i></a>
                        <a class="joc_social" title=" Instagram" target="_blank" href="#/"><i class="ri-instagram-line  ri-xl"></i></a>
                        <a class="joc_social" title=" Linkedin" target="_blank" href="#/"><i class="ri-linkedin-line ri-xl"></i></a>
                        <a class="joc_social" title="Youtube " target="_blank" href="#/"><i class="ri-youtube-line  ri-xl"></i></a>
                    </div> */}

                </div>
            </div>
        </div>              
      </section>

    <div class="section-process pb-100">
        <div class="container">
            <div class="process_row" >
                 <div class="process-content">  
                    <h3 class="h3-heading">Are you ready to embark on this blockchain journey with us? </h3>
                    <p class="paragraph-large"> Join PortoLink today and experience a new era of financial possibilities. Discover the PortoLink ecosystem, access tailor-made DeFi solutions, engage with innovative smart contracts, and actively participate in our community. </p>
                </div>
                {/* <a href="#/" class="btn btn-primary" > Get Started </a> */}
            </div>
        </div>
    </div> 
</div>
  )
}

export default About
