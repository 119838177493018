import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay} from 'swiper/modules';

const DeveloperOverview = () => {
  return (

    <div class="ee_wrapper ins_wrapper">

      <section class="work-part ins_part ptb-100" style={{ backgroundImage: "url(images/ins_part_bg.png)", backgroundPosition: "center" }}>
        <div class="container">
          <div class="row">
            <div class="col-md-6 flex-align " >
              <div class="work-box">
                <div class="section-heading text-start">
                  <h2 class="heading-title"> <b>Developers Hub: Building the Future of Cross-Chain Innovation</b> </h2>
                  <p class="heading-des mb-4 mb-md-5">
                    Welcome to the ProtoLink Developers Hub, where groundbreaking ideas meet cutting-edge blockchain technology. Whether you're a seasoned developer or a curious enthusiast, this is your gateway to shaping the future of decentralized finance.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-6 text-center flex-align justify-center " >
              <img src="images/deverloper_main_img.svg" alt="banner" />
            </div>



          </div>
        </div>
      </section>

      <section class="brand_sec p-0">
        <div class="container">
          <a href="#/" class="brand_card brand_card_x" style={{ background: "#fff1e4" }}>
            <i class="ri-file-paper-line ri-xl"></i>
            <h5>
              Documentation <i class="ri ri-arrow-right-line" ></i>
            </h5>
          </a>
          <a href="#/" class="brand_card brand_card_x" style={{ background: "#E4F2FF" }}>

            <i class="ri-github-fill ri-xl"></i>
            <h5>
              GitHub <i class="ri ri-arrow-right-line" ></i>
            </h5>

          </a>
          <a href="#/" class="brand_card brand_card_x" style={{ background: "#D8FCEF" }} >
            <i class="ri-chat-1-line ri-xl"></i>
            <h5>
              Developer Chat <i class="ri ri-arrow-right-line" ></i>
            </h5>
          </a>
        </div>
      </section>


      <section class="feature-part pt-100 pb-10">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="section-heading text-center pb-65">
                <label class="sub-heading">Why with us</label>
                <h2 class="heading-title">Why Develop with ProtoLink? </h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4  pb-80 ">
              <div class="feature-box">
                <div class="feature-icon">
                  <img src="images/wb_1.svg" alt="Safe &amp; Secure" />
                </div>
                <div class="feature-contain pt-25">
                  <a href="/#" class="feature-title"> Unmatched Cross-Chain Interoperability </a>
                  <p class="feature-des">ProtoLink stands at the forefront of blockchain innovation, offering developers unprecedented cross-chain interoperability. Seamlessly integrate your applications with ProtoLink's protocol to enable users to transfer assets across diverse blockchain networks effortlessly.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4  pb-80 ">
              <div class="feature-box">
                <div class="feature-icon">
                  <a href="/#"><img src="images/wb_2.svg" alt="Early Bonus" /></a>
                </div>
                <div class="feature-contain pt-25">
                  <a href="/#" class="feature-title"> Next-Level Smart Contracts </a>
                  <p class="feature-des">Experience a paradigm shift in smart contract capabilities with ProtoLink. Our platform introduces a new standard of efficiency and security, empowering developers to create sophisticated and secure decentralized applications (DApps) with ease. </p>
                </div>
              </div>
            </div>
            <div class="col-md-4  pb-80  ">
              <div class="feature-box">
                <div class="feature-icon">
                  <a href="/#"><img src="images/wb_3.svg" alt="Univarsal Access" /></a>
                </div>
                <div class="feature-contain pt-25">
                  <a href="/#" class="feature-title"> Dynamic Smart Contracts  </a>
                  <p class="feature-des">ProtoLink introduces a revolutionary approach to smart contracts. Our platform empowers developers with dynamic smart contracts that can adapt and evolve based on changing conditions. This flexibility opens the door to a new era of decentralized applications (DApps) that respond dynamically to user interactions, creating a more sophisticated and user-friendly experience.</p>
                </div>
              </div>
            </div>
            <div class="col-md-4  pb-80  ">
              <div class="feature-box">
                <div class="feature-icon">
                  <a href="/#"><img src="images/wb_4.svg" alt="Secure Storage" /></a>
                </div>
                <div class="feature-contain pt-25">
                  <a href="/#" class="feature-title">Resilient Liquidity </a>
                  <p class="feature-des">ProtoLink ensures a robust liquidity environment for decentralized finance (DeFi) applications. With ProtoLink's liquidity solutions, developers can create DApps that thrive in a well-supported and liquid ecosystem. This feature is crucial for enhancing the trading experience, attracting users, and fostering a vibrant DeFi community. </p>
                </div>
              </div>
            </div>
            <div class="col-md-4  pb-80  ">
              <div class="feature-box">
                <div class="feature-icon">
                  <a href="/#"><img src="images/wb_5.svg" alt="Low Cost" /></a>
                </div>
                <div class="feature-contain pt-25">
                  <a href="/#" class="feature-title">Customized for DeFi  </a>
                  <p class="feature-des">ProtoLink is tailor-made for the DeFi landscape. Developers can harness the platform's capabilities to create decentralized financial solutions that offer innovative features such as automated market making, yield farming, and decentralized exchanges. ProtoLink's DeFi focus provides a solid foundation for developers to contribute to the evolution of decentralized finance. </p>
                </div>
              </div>
            </div>
            <div class="col-md-4  pb-80  ">
              <div class="feature-box">
                <div class="feature-icon">
                  <a href="/#"><img src="images/wb_6.svg" alt="Several Profit" /></a>
                </div>
                <div class="feature-contain pt-25">
                  <a href="/#" class="feature-title">Exceptionally Secure</a>
                  <p class="feature-des">Security is a top priority at ProtoLink. The platform employs state-of-the-art cryptographic techniques and robust security measures to safeguard user assets and data. Developers can build with confidence, knowing that their applications are supported by a highly secure infrastructure, creating trust among users in the decentralized ecosystem. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className=" cba_sec   ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-6  ">
              <div className="work-box">
                <h2>
                  Types of projects you can   <span>
                    build
                  </span>
                </h2>

                <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <button className="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Exchanges  </button>
                  <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false"> Lending</button>
                  <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Derivatives </button>
                  <button className="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false"> Savings </button>
                  <button className="nav-link" id="v-pills-settings2-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings2" type="button" role="tab" aria-controls="v-pills-settings2" aria-selected="false"> Insurance </button>
                  <button className="nav-link" id="v-pills-settings3-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings3" type="button" role="tab" aria-controls="v-pills-settings3" aria-selected="false"> Options </button>
                </div>


              </div>
            </div>
            <div className="col-md-6 flex-align">
              <div className="tab-content" id="v-pills-tabContent">
                <div className="tab-pane   show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                  <div className="cba_content" >
                    <h4 className="mb-3 " >
                      Exchanges </h4>
                    <p className="" >
                      Launch MEV-resistant DEXs that offer perpetual futures, zero gas fees, lightning fast transaction speeds, broad liquidity and instant finality using Injective’s world-class Web3 modules such as the first fully on-chain orderbook.
                    </p>
                  </div>
                </div>
                <div className="tab-pane  " id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                  <div className="cba_content" >
                    <h4 className="mb-3 " >
                      Lending </h4>
                    <p className="" >
                      Harness the power of Injective’s institutional integrations to generate truly capital efficient, non-custodial, algorithmic lending and money market protocols.

                    </p>
                  </div>
                </div>
                <div className="tab-pane  " id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                  <div className="cba_content" >
                    <h4 className="mb-3 " >
                      Derivatives </h4>
                    <p className="" >
                      Create a wide array of sophisticated decentralized derivatives applications and structured products that are able to effortlessly work across sovereign blockchain networks.
                    </p>
                  </div>
                </div>
                <div className="tab-pane  " id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                  <div className="cba_content" >
                    <h4 className="mb-3 " >
                      Savings </h4>
                    <p className="" >
                      Leverage a suite of intuitive developer tooling to ship truly decentralized savings products with no minimums and no restrictions.
                    </p>
                  </div>
                </div>
                <div className="tab-pane  " id="v-pills-settings2" role="tabpanel" aria-labelledby="v-pills-settings2-tab">
                  <div className="cba_content" >
                    <h4 className="mb-3 " >
                      Insurance </h4>
                    <p className="" >
                      Underwrite a new DAO governed financial paradigm to provide bespoke insurance policies and coverage for all Web3 user needs.
                    </p>
                  </div>
                </div>
                <div className="tab-pane  " id="v-pills-settings3" role="tabpanel" aria-labelledby="v-pills-settings3-tab">
                  <div className="cba_content" >
                    <h4 className="mb-3 " >
                      Option </h4>
                    <p className="" >
                      Deploy disruptive on-chain options products, prediction markets and automated strategies using Injective’s options module to bring forth a new era of financial innovation.
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>


        <div class="container ptb-100">
          <div class=" ">
            <h3 class="heading-title-sm">  Build with ProtoLink Today  </h3>
            {/* <br/> */}
            <span>ProtoLink is more than a blockchain protocol; it's an invitation to pioneers and visionaries like yourself. Start building the future of decentralized finance with ProtoLink – where innovation knows no bounds.</span>
          </div>

          <div class="table-responsive table_card" >
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col"> </th>
                  <th scope="col"> <span> <img src="images/table_bitcoin.svg" width="20" alt="" />Ethereum</span> </th>
                  <th scope="col"> <span> <img src="images/table_ethereum.svg" width="25" class="me-2" alt="" />Binance </span> </th>
                  <th scope="col"><span> <img src="images/table_polkadot.svg" width="20" class="me-2" alt="" /> Polygon</span> </th>
                  <th scope="col"> <span> <img src="images/table_polygon.svg" width="25" class="me-2" alt="" /> Tron</span> </th>
                  <th scope="col"> <span> <img src="images/table_protolink.svg" width="25" class="me-2" alt="" /> Solana</span> </th>
           
                  <th scope="col"> <span> <img src="images/table_protolink.svg" width="25" class="me-2" alt="" /> Protolink</span> </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row">Speed</td>
                  <td>7 tps</td>
                  <td>14 tps</td>
                  <td>1,500 tps</td>
                  <td>7,200 tps</td>
                  <td class="ac_td" >25,000 tps</td>
               
                  <td>1,500 tps</td>
                </tr>
                <tr>
                  <td scope="row">Time to Finality</td>
                  <td> 60 min</td>
                  <td> 5 min</td>
                  <td>30 sec  </td>
                  <td>5 min</td>
                  <td class="ac_td" >Instant</td>
                
                  <td>30 sec  </td>
                </tr>
                <tr>
                  <td scope="row">Block Time</td>
                  <td> 10 min</td>
                  <td> 12 sec</td>
                  <td>6 sec</td>
                  <td>2 sec</td>
                  <td class="ac_td" >0.8 sec</td>
             
                  <td>2 sec</td>
                </tr>
                <tr>
                  <td scope="row">Avg. Fee Per Transaction</td>
                  <td> $12</td>
                  <td> $2.80</td>
                  <td>$0.015</td>
                  <td>$0.00012</td>
                  <td class="ac_td" >$0.0002</td>
                  
                  <td>$0.00012</td>
                </tr>
                <tr>
                  <td scope="row">Consensus Mechanism</td>
                  <td> Proof of Work</td>
                  <td> Proof of Stake</td>
                  <td>Proof of Stake</td>
                  <td>Proof of Stake</td>
                  <td class="ac_td" >Proof of Stake</td>
                
                  <td>Proof of Stake</td>
                </tr>
                <tr>
                  <td scope="row">MEV Resistant</td>
                  <td> <img src="images/table_cancel.svg" class="img-fluid" alt="" /> </td>
                  <td> <img src="images/table_cancel.svg" class="img-fluid" alt="" /> </td>
                  <td><img src="images/table_cancel.svg" class="img-fluid" alt="" /> </td>
                  <td><img src="images/table_cancel.svg" class="img-fluid" alt="" /> </td>
                  <td class="ac_td" ><img src="images/table_ok.svg" class="img-fluid" alt="" /></td>
                  
                  <td><img src="images/table_cancel.svg" class="img-fluid" alt="" /> </td>
                </tr>
                <tr>
                  <td scope="row">DeFi Primitives</td>
                  <td> <img src="images/table_cancel.svg" class="img-fluid" alt="" /> </td>
                  <td> <img src="images/table_cancel.svg" class="img-fluid" alt="" /> </td>
                  <td><img src="images/table_cancel.svg" class="img-fluid" alt="" /> </td>
                  <td><img src="images/table_cancel.svg" class="img-fluid" alt="" /> </td>
                  <td><img src="images/table_cancel.svg" class="img-fluid" alt="" /> </td>
           
                  <td class="ac_td" > On-chain order book, <br /> derivatives, oracle, <br /> binary options</td>
                </tr>
                <tr>
                  <td scope="row">Native Interoperability</td>
                  <td> <img src="images/table_cancel.svg" class="img-fluid" alt="" /> </td>
                  <td> <img src="images/table_cancel.svg" class="img-fluid" alt="" /> </td>
                  <td> <img src="images/table_cancel.svg" class="img-fluid" alt="" /> </td>
                  <td> Polkadot Parachains  </td>
                  <td><img src="images/table_cancel.svg" class="img-fluid" alt="" /> </td>
                
                  <td class="ac_td" > Ethereum and Cosmos IBC </td>
                </tr>
                <tr>
                  <td scope="row">Multichain Smart Contracts</td>
                  <td> <img src="images/table_cancel.svg" class="img-fluid" alt="" /> </td>
                  <td> <img src="images/table_cancel.svg" class="img-fluid" alt="" /> </td>
                  <td> <img src="images/table_cancel.svg" class="img-fluid" alt="" /> </td> 
                  <td><img src="images/table_cancel.svg" class="img-fluid" alt="" /> </td>
                  <td><img src="images/table_cancel.svg" class="img-fluid" alt="" /> </td>
                
                  <td class="ac_td" ><img src="images/table_ok.svg" class="img-fluid" alt="" /></td>
                </tr>


              </tbody>
            </table>
          </div>

        </div>

      </section>


      <section class="sm_sec">
        <div class="container-xxl">
          <div class=" ">
            <div class=" ">
            <h2 class="heading-title"> ProtoLink: Elevating Finance, Empowering Institutions –    <span>Hear What Our Users Have to Say!</span>  </h2>
            </div>
            <div class=" program_swiper sm_owl  sm_owl2  owl-theme">
            <Swiper
                            spaceBetween={50}
                            slidesPerView={1}
                            loop={true}
                            autoplay={{
                                delay: 2000,
                              }}
                              modules={[Autoplay]}
                              breakpoints={{
                                320: {
                                  slidesPerView: 1,
                                  spaceBetween: 10,
                                },
                                640: {
                                  slidesPerView: 1,
                                  spaceBetween: 10,
                                },
                                768: {
                                  slidesPerView: 2,
                                  spaceBetween: 10,
                                },
                                1024: {
                                  slidesPerView: 2,
                                  spaceBetween: 10,
                                },
                              }}
                        >
             <SwiperSlide>  <div class="item"  >
                <div class="clint">
                  <div class="client_header" >
                  </div>
                  <div class="clint-box">
                    <h4 className='mb-2 text-center'>Seamless Financial Innovation </h4>
                    <p>
                      "
                      Protolink has built the first truly MEV resistant layer one that is optimized for building DeFi applications. Developers can access a robust set of Web3 primitives such as a fully on-chain orderbook, multichain smart contracts, binary options and much more to build next generation dApps. We have been proud and early supporters of Protolink and continue to be impressed by the astounding growth of the broader Protolink ecosystem.
                      "
                    </p>
                    <p>
                      {/* <div class=" r_user mt-5 d-flex alin-items-center justify-content-center">
                        <img src="images/user.avif" width="45" class="img-fluid" />
                        <div class="r_user_c flex flex-col ms-3">
                          <div class="  ">
                            Kanav Kariya
                          </div>
                          <small class=" ">
                            President
                          </small>
                        </div>
                      </div> */}
                    </p>
                  </div>
                </div>
              </div></SwiperSlide> 
              <SwiperSlide>    <div class="item"  >
                <div class="clint">
                  <div class="client_header" >
                  </div>
                  <div class="clint-box">
                    <h4 className='mb-2 text-center'>Unmatched Security Standards </h4>
                    <p>
                    "ProtoLink's institutional-grade security is second to none. As a financial institution, trust is paramount, and ProtoLink delivers with its robust security measures. The transparent reporting and compliance with regulatory frameworks provide us with the confidence to navigate the crypto landscape. ProtoLink is indeed a secure gateway to the future of finance."
                    </p>
                    <p>
                      {/* <div class=" r_user mt-5 d-flex alin-items-center justify-content-center">
                        <img src="images/user.avif" width="45" class="img-fluid" />
                        <div class="r_user_c flex flex-col ms-3">
                          <div class="  ">
                            Kanav Kariya
                          </div>
                          <small class=" ">
                            President
                          </small>
                        </div>
                      </div> */}
                    </p>
                  </div>
                </div>
              </div></SwiperSlide> 
              <SwiperSlide>   <div class="item"  >
                <div class="clint">
                  <div class="client_header" >
                  </div>
                  <div class="clint-box">
                    <h4 className='mb-2 text-center'>Dynamic Asset Accessibility </h4>
                    <p>
                    "ProtoLink's multi-asset support has expanded our horizons in the world of decentralized finance. The ability to access a diverse range of digital assets across popular blockchains is a game-changer for our investment strategies. ProtoLink has become an essential tool for us to stay agile in a rapidly evolving financial landscape."
                    </p>
                    <p>
                      {/* <div class=" r_user mt-5 d-flex alin-items-center justify-content-center">
                        <img src="images/user.avif" width="45" class="img-fluid" />
                        <div class="r_user_c flex flex-col ms-3">
                          <div class="  ">
                            Kanav Kariya
                          </div>
                          <small class=" ">
                            President
                          </small>
                        </div>
                      </div> */}
                    </p>
                  </div>
                </div>
              </div></SwiperSlide> 
              <SwiperSlide>   <div class="item"  >
                <div class="clint">
                  <div class="client_header" >
                  </div>
                  <div class="clint-box">
                    <h4 className='mb-2 text-center'>Innovative Financial Modules </h4>
                    <p>
                    "Building applications on ProtoLink is a breeze, thanks to its innovative financial modules. We've harnessed the power of the on-chain derivatives orderbook and other cutting-edge features to create groundbreaking financial applications. ProtoLink's commitment to pushing the boundaries of what's possible in finance is truly commendable."
                    </p>
                    <p>
                      {/* <div class=" r_user mt-5 d-flex alin-items-center justify-content-center">
                        <img src="images/user.avif" width="45" class="img-fluid" />
                        <div class="r_user_c flex flex-col ms-3">
                          <div class="  ">
                            Kanav Kariya
                          </div>
                          <small class=" ">
                            President
                          </small>
                        </div>
                      </div> */}
                    </p>
                  </div>
                </div>
              </div></SwiperSlide> 
              <SwiperSlide>   <div class="item"  >
                <div class="clint">
                  <div class="client_header" >
                  </div>
                  <div class="clint-box">
                    <h4 className='mb-2 text-center'> Low Fees, High Value  </h4>
                    <p>
                    "ProtoLink's low transaction fees and the ability to batch operations into a single transaction provide excellent value for our institution. The cost-effectiveness, coupled with the platform's efficiency, has significantly contributed to our bottom line. ProtoLink is not just a financial platform; it's a strategic asset for our institution."
                    </p>
                    <p>
                      {/* <div class=" r_user mt-5 d-flex alin-items-center justify-content-center">
                        <img src="images/user.avif" width="45" class="img-fluid" />
                        <div class="r_user_c flex flex-col ms-3">
                          <div class="  ">
                            Kanav Kariya
                          </div>
                          <small class=" ">
                            President
                          </small>
                        </div>
                      </div> */}
                    </p>
                  </div>
                </div>
              </div></SwiperSlide> 
              <SwiperSlide>   <div class="item"  >
                <div class="clint">
                  <div class="client_header" >
                  </div>
                  <div class="clint-box">
                    <h4 className='mb-2 text-center'>Future-Ready Solutions  </h4>
                    <p>"ProtoLink's forward-thinking approach has made it a crucial partner for our institution. The platform's readiness to adapt to the evolving needs of the financial industry ensures that we are always at the forefront of innovation. ProtoLink is not just a platform; it's a catalyst for our institution's future success in the digital age."
                    </p>
                    <p>
                      {/* <div class=" r_user mt-5 d-flex alin-items-center justify-content-center">
                        <img src="images/user.avif" width="45" class="img-fluid" />
                        <div class="r_user_c flex flex-col ms-3">
                          <div class="  ">
                            Kanav Kariya
                          </div>
                          <small class=" ">
                            President
                          </small>
                        </div>
                      </div> */}
                    </p>
                  </div>
                </div>
              </div></SwiperSlide> 
              </Swiper>


            </div>
          </div>
        </div>
      </section>

    </div>

  )
}

export default DeveloperOverview
