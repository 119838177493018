import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay} from 'swiper/modules';
import 'swiper/css';

const Institution = () => {
    return (
        <div className="ee_wrapper ins_wrapper">

            <section className="work-part ins_part ptb-100"
                style={{ backgroundImage: "url(images/ins_part_bg.png)MozAnimation", backgroundPosition: "center" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 flex-align " >
                            <div className="work-box">
                                <div className="section-heading text-start">
                                    <label> Ovreview of </label>
                                    <h2 className="heading-title"> <b>Institutions</b> </h2>
                                    <p className="heading-des mb-4 mb-md-5">
                                        ProtoLonk is revolutionizing finance in collaboration with global leaders. Its state-of-the-art infrastructure is optimized for crafting diverse institutional-grade financial applications and use cases.
                                        <br />
                                        <small>ProtoLink has garnered collaboration from the world's foremost financial institutions.</small>
                                    </p>
                                    {/* <a href="#/" className="btn">Get in touch </a>
                                    <a href="#/" className=" btn-link px-4  "> Join Institutional  <i className="ms-1 ri-arrow-right-circle-line"></i> </a> */}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 text-center flex-align justify-center " >
                            <div className="work-box">
                                <div className="work-box-bg"></div>
                                <img src="images/Institutions_img.svg" alt="Work Process" />
                            </div>
                        </div>



                    </div>
                </div>
            </section>

            <section className="brand_sec p-0">
                <div className="container">
                    <div className="brand_card" style={{ background: "#fff" }}>
                        <img src="images/ins1.png" className="img-fluid" alt="" />
                    </div>
                    <div className="brand_card" style={{ background: "#E4F2FF" }}>
                        <img src="images/ins2.png" className="img-fluid" alt="" />
                    </div>
                    <div className="brand_card" style={{ background: "#D8FCEF" }} >
                        <img src="images/ins3.png" className="img-fluid" alt="" />
                    </div>
                </div>
            </section >


            <section className="feature-part pt-100 pb-10">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-heading text-center pb-65">
                                <label className="sub-heading">Why Choose us</label>
                                <h2 className="heading-title">What makes financial institutions opt
                                    <br />  for ProtoLink? </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4  pb-80 ">
                            <div className="feature-box">
                                <div className="feature-icon">
                                    <img src="images/feature_1.svg" alt="Safe &amp; Secure" />
                                </div>
                                <div className="feature-contain pt-25">
                                    <a href="#/" className="feature-title">Crafted for institutions,</a>
                                    <p className="feature-des">ProtoLink’s core infrastructure stands out with its unique optimization, providing transparent reporting and instant settlement for all transactions. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4  pb-80 ">
                            <div className="feature-box">
                                <div className="feature-icon">
                                    <a href="#/"><img src="images/feature_2.svg" alt="Early Bonus" /></a>
                                </div>
                                <div className="feature-contain pt-25">
                                    <a href="#/" className="feature-title">Advanced Financial Modules </a>
                                    <p className="feature-des">Take advantage of PortoLink's world class financial modules such as the first on-chain derivatives orderbook to construct groundbreaking applications. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4  pb-80  ">
                            <div className="feature-box">
                                <div className="feature-icon">
                                    <a href="#/"><img src="images/feature_3.svg" alt="Univarsal Access" /></a>
                                </div>
                                <div className="feature-contain pt-25">
                                    <a href="#/" className="feature-title">Near-Zero Fees </a>
                                    <p className="feature-des">Enjoy near-zero fees when settling transactions on ProtoLink, with the added advantage of batching thousands of operations into a single transaction. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4  pb-80  ">
                            <div className="feature-box">
                                <div className="feature-icon">
                                    <a href="#/"><img src="images/feature_4.svg" alt="Secure Storage" /></a>
                                </div>
                                <div className="feature-contain pt-25">
                                    <a href="#/" className="feature-title">Diverse Asset Accessibility</a>
                                    <p className="feature-des">Effortlessly tap into a diverse range of digital assets across leading blockchains, alongside access to sophisticated structured products with ProtoLink's multi-asset support.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4  pb-80  ">
                            <div className="feature-box">
                                <div className="feature-icon">
                                    <a href="#/"><img src="images/feature_5.svg" alt="Low Cost" /></a>
                                </div>
                                <div className="feature-contain pt-25">
                                    <a href="#/" className="feature-title">Liquidity Depth  </a>
                                    <p className="feature-des">Gain access to transparent liquidity throughout decentralized platforms with a straightforward one-time onboarding process, thanks to ProtoLink's deep liquidity.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4  pb-80  ">
                            <div className="feature-box">
                                <div className="feature-icon">
                                    <a href="#/"><img src="images/feature_6.svg" alt="Several Profit" /></a>
                                </div>
                                <div className="feature-contain pt-25">
                                    <a href="#/" className="feature-title">Secure Foundations </a>
                                    <p className="feature-des">Institutional-Grade Security Benefit from PortoLink's top-tier security standards, trusted by global financial institutions.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>





            <section className=" cba_sec   ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6  ">
                            <div className="work-box">
                                <h2>
                                    Institutional Possibilities with  <span>
                                        Protolink
                                    </span>
                                </h2>

                                <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <button className="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Develop Corporate Applications  </button>
                                    <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false"> Explore Institutional Offerings</button>
                                    <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false"> Unlock Unrivaled Incentives </button>
                                    <button className="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false"> Utilize Permissioned Gateways </button>
                                </div>


                            </div>
                        </div>
                        <div className="col-md-6 flex-align">
                            <div className="tab-content" id="v-pills-tabContent">
                                <div className="tab-pane   show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                    <div className="cba_content" >
                                        <img alt='' src="images/cba_img.svg" className="img img-fluid  mb-5" />

                                        <h4 className="mb-3 " >Develop Corporate Applications </h4>
                                        <p className="" >
                                            Leveraging PortoLink's resilient financial modules enables institutions to swiftly create enterprise-grade applications tailored for trading, asset tokenization, lending, treasury management, and beyond.
                                        </p>
                                    </div>
                                </div>
                                <div className="tab-pane  " id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                    <div className="cba_content" >
                                        <img alt='' src="images/cba_img.svg" className="img img-fluid   mb-5" />
                                        <h4 className="mb-3 " >
                                            Explore Institutional Offerings </h4>
                                        <p className="" >
                                            Leading asset managers and high-frequency traders leverage products and applications on PortoLink to tap into cutting-edge digital asset technology. Institutions can participate in a diverse range of products covering trading, lending, staking, and governance.

                                        </p>
                                    </div>
                                </div>
                                <div className="tab-pane  " id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                    <div className="cba_content" >
                                        <img alt='' src="images/cba_img.svg" className="img img-fluid   mb-5" />
                                        <h4 className="mb-3 " >
                                            Unlock Unrivaled Incentives </h4>
                                        <p className="" >
                                            The Portolink ecosystem tailors exclusive institutional programs to offer a wide range of incentives aligned with your unique objectives. Trading firms and market makers can seamlessly interface with dApps on PortoLink to actively seize new opportunities.
                                        </p>
                                    </div>
                                </div>
                                <div className="tab-pane  " id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                    <div className="cba_content" >
                                        <img alt='' src="images/cba_img.svg" className="img img-fluid   mb-5" />
                                        <h4 className="mb-3 " >
                                            Utilize Permissioned Gateways </h4>
                                        <p className="" >
                                            Access through controlled entry points and liquidity pools to adhere to existing regulatory frameworks. PortoLink also ensures transparent data reporting to uphold compliant crypto financing.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="container ptb-100" >
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="section-heading text-center pb-65">
                                <label className="sub-heading">Why Choose us</label>
                                <h2 className="heading-title">Why financial institutions <br /> Prefer PortoLink
                                </h2>
                            </div>
                        </div>
                        <div className='row'>
                        <Swiper
                            spaceBetween={50}
                            slidesPerView={2}
                            autoplay={{
                                delay: 2000,
                              }}
                              modules={[Autoplay]}
                              breakpoints={{
                                320: {
                                  slidesPerView: 1,
                                  spaceBetween: 10,
                                },
                                640: {
                                  slidesPerView: 1,
                                  spaceBetween: 10,
                                },
                                768: {
                                  slidesPerView: 2,
                                  spaceBetween: 10,
                                },
                                1024: {
                                  slidesPerView: 2,
                                  spaceBetween: 10,
                                },
                              }}
                        >
                            <SwiperSlide><div className="" >
                                <div className="clint client_riview" >
                                    {/* <img src="images/ins1.png" className="img-fluid" alt="" /> */}
                                    <div className="clint-box">
                                        <p>
                                            "ProtoLink has transformed our financial operations with its robust infrastructure. The platform's multi-asset support and institutional-grade security are unparalleled. It has become an integral part of our digital asset strategy, providing seamless access to diverse assets and ensuring the highest standards of security."
                                        </p>

                                        <div className=" r_user mt-5 d-flex alin-items-center justify-content-center">
                                            {/* <img alt='' src="images/user.avif" width="45" className="img-fluid" /> */}
                                            <div className="r_user_c flex flex-col ms-3">
                                                <div className="  ">
                                                    XYZZ
                                                </div>
                                                <small className=" ">
                                                    XYZZ
                                                </small>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div></SwiperSlide> 
                            <SwiperSlide>   <div className="" >
                                <div className="clint client_riview" >
                                    {/* <img src="images/ins1.png" className="img-fluid" alt="" /> */}
                                    <div className="clint-box">
                                        <p>
                                            "As a leading financial institution, we have found ProtoLink's deep liquidity to be a game-changer. The one-time onboarding process for accessing transparent liquidity across decentralized venues is simple and effective. ProtoLink's commitment to security and its innovative financial modules make it a preferred choice for our institutional needs."

                                        </p>

                                        <div className=" r_user mt-5 d-flex alin-items-center justify-content-center">
                                            {/* <img alt='' src="images/user.avif" width="45" className="img-fluid" /> */}
                                            <div className="r_user_c flex flex-col ms-3">
                                                <div className="  ">
                                                    XYZZ
                                                </div>
                                                <small className=" ">
                                                    XYZZ
                                                </small>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div></SwiperSlide> 
                            <SwiperSlide>    <div className="" >
                                <div className="clint client_riview" >
                                    {/* <img src="images/ins1.png" className="img-fluid" alt="" /> */}
                                    <div className="clint-box">
                                        <p>
                                            "ProtoLink has exceeded our expectations in terms of building enterprise applications. The platform's financial modules enable us to rapidly develop and deploy applications for various financial activities, from trading to treasury management. The institutional products offered by ProtoLink align perfectly with our objectives, and opportunities in the evolving digital asset landscape."

                                        </p>

                                        <div className=" r_user mt-5 d-flex alin-items-center justify-content-center">
                                            {/* <img alt='' src="images/user.avif" width="45" className="img-fluid" /> */}
                                            <div className="r_user_c flex flex-col ms-3">
                                                <div className="  ">
                                                    XYZZ
                                                </div>
                                                <small className=" ">
                                                    XYZZ
                                                </small>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div></SwiperSlide> 
                            </Swiper>
                            </div>
                    </div>
                </div>


            </section>

        </div >
    )
}

export default Institution
